import create from 'zustand';
import {persist} from 'zustand/middleware';
import {ICAuthResponse} from '../types/ic-auth-responser';

interface IState {
  user: ICAuthResponse;
  loading: boolean;
  language: string;
  setUser: (user: ICAuthResponse) => void
  setLoading: (loading: boolean) => void;
  setLanguage: (language: string) => void;
  deleteUser: () => void;
}

export const useStore = create<IState>(
  persist(
    (set, get) => ({
      user: {},
      loading: false,
      language: '',
      setUser: (user) => set((state) => ({...state, user})),
      setLoading: (loading) => set((state) => ({...state, loading})),
      setLanguage: (language) => set((state) => ({...state, language})),
      deleteUser: () => set({user: null})
    }),
    {
      name: 'ic-app-viasat',
      getStorage: () => sessionStorage,
    }
  )
);
